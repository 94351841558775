var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{key:_vm.detailsBlog.id,staticClass:"detail-news"},[_c('div',{staticClass:"w-full grid h-96 justify-item-center font-extrabold text-white content-center",style:({
                backgroundImage: 'url('+_vm.detailsBlog.image+')',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                backdropFilter: 'blur(10px)',
            })},[_c('div',{staticClass:"w-1/2 mx-auto"},[_c('span',{staticClass:"font-normal text-xs md:text-lg text-white"},[_vm._v(" "+_vm._s(_vm.$moment(_vm.detailsBlog.created_at).format("MMMM Mo, YYYY"))+" ")]),_c('h1',{staticClass:"text-5xl"},[_vm._v(" "+_vm._s(_vm.detailsBlog.title)+" ")])])]),_c('div',{staticClass:"container max-w-screen-lg lg:max-w-screen-2xl xl:max-w-screen-xl mx-auto px-3 md:px-8 mb-8"},[_c('div',{staticClass:"px-3 md:px-16 py-4 md:py-6 w-full"},[_c('div',{staticClass:"w-full py-3 md:py-6"},[_c('p',{staticClass:"font-normal text-black2 text-base md:text-lg leading-5 md:leading-7",domProps:{"innerHTML":_vm._s(_vm.detailsBlog.content)}})])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }